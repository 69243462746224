function getParams(url) {

    const urlQuery = url.searchParams;

    const query = urlQuery.has("course")
        ? urlQuery.get("course")
        : "";
    const options = urlQuery.has("widgets")
        ? urlQuery.get("widgets").split(",")
        : [];
    const place = urlQuery.has("place") ? urlQuery.get("place") : "course-nav";

    return [query, options, place];
}

function updateQuery(queries) {
    const url = new URL(window.location.href);
    const urlQuery = url.searchParams;
    for (const query in queries) {
        urlQuery.set(query, queries[query]);
    }
    window.history.pushState({}, "", url);
}

module.exports = {getParams, updateQuery};