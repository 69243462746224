import { useContext, useEffect, useState } from "react";
import { Button, FormFieldGroup, Heading, IconAdminToolsLine, Link, ScreenReaderContent, Spinner, Tabs, Text, TextInput, View } from '@instructure/ui';
import { QueryDetails } from "./app";
import { getSubs, verify } from "./search";
import { Guide } from "./guide";
import { Key } from "./key";
import { Exams } from "./exams";
import { Reserves } from "./reserves";
import { Databases } from "./databases";
import { Referencing } from "./referencing";
import Tewaharoa from "./tewaharoa";
import LibraryInfoSection from "./libraryInfoSection";

export const options = [
    {value: "searchbar", label: "Te Waharoa search bar", comp: <Tewaharoa />, desc: "Selecting this option embeds Te Waharoa search bar" },
    {value: "libraryinfo", label: "Library Information", comp: <LibraryInfoSection />, desc: "Creates a section with access to library website, assignment help guide page, study room booking page and contact details"},
    {value: "guide", label: "Subject librarian/guide", comp: <Guide key="guide" intro="Subject guides provide information on online resources and advice useful for your study and research. You can also contact your subject librarian directly for personal in-depth help with your research."/>},
    {value: "key", label: "Key resources", comp: <Key key="key" intro="The following have been identified as key resources for the subjects related to this course." />, desc: "Resources selected by subject librarians as being particularly useful for the course’s subject fields"},
    {value: "reserves", label: "Course reserves", comp: <Reserves key="reserves" intro={<>Items specifically relevant to your course are placed on <em>course reserve</em> and are made available on short-term loan (2 hours or 3 days).</>}/>},
    {value: "exams", label: "Past exams", comp: <Exams key="exams" intro={"Past exam papers for your course are available for viewing online."}/>, desc: "Note: only exam papers held in the Library"},
    {value: "databases", label: "Databases", comp: <Databases key="databases" intro="Below are listed databases and other online resources that are relevant to the subject fields of your course."/>},
    {value: "referencing", label: "Citing/referencing guide", comp: <Referencing key="referencing" intro="Guides for referencing and citation formatting are available for the subjects relevant to your course."/>}
  ];

function NotSetUp() {

  const [code, setCode] = useState();
  const [valid, setValid] = useState(false);
  const [error,setError] = useState(null);

  function handleSubmit(e) {
    e.preventDefault();
    window.open(`https://www.wgtn.ac.nz/library/course-code-search?course-code=${code}`,"ccTab");
  }

  function handleChange(e) {
    setCode(e.target.value);
    setValid(verify(e.target.value));
  }

  function handleBlur() {
    valid ? setError(null) : setError([{text: "Enter valid course code", type: "error"}]);
  }

  return (
    <>
    <Heading level="h1" margin="none none medium none">Search Library resources for your course</Heading>
    <View as="form" onSubmit={handleSubmit} margin="small none" padding="small none" maxWidth="250px">
        <FormFieldGroup layout="inline" description={<ScreenReaderContent>Search Library resources by course code</ScreenReaderContent>} rowSpacing="small" vAlign="middle">
          <TextInput
            renderLabel="Enter course code (e.g. LAWS212)"
            value={code}
            onChange={handleChange}
            placeholder="Enter course code"
            onSubmit={handleSubmit}
            onBlur={handleBlur}
            messages={error}
          />
          <Button type="submit" interaction={valid ? "enabled" : "disabled"}>Go</Button>
        </FormFieldGroup>
    </View>
    </>
  )
}

function Reconfigure() {
  const { course } = useContext(QueryDetails);
  const reconfigureLink = "/configure?course=" + course;
  return (
    <View background="secondary" as="div" borderWidth="small" shadow="above" margin="none none medium">
      <View as="p" margin="medium">
        <Link href={reconfigureLink} isWithinText={false}>
          <Text size="large">
            <View as="span" padding="none small none none"><IconAdminToolsLine size="small" /></View>Change the selected widgets
          </Text>
        </Link>
      </View>
    </View>
  )
}

function Results() {

    const { place, widgets } = useContext(QueryDetails);
    const selectedOptions = widgets.map(option => options.find(x => x.value === option));
    // const subjectList = subjects.map(subject => subject.name).join(", ");

    const [isAdmin,setIsAdmin] = useState(false);
    const [selectedTab,setSelectedTab] = useState(0);

    useEffect(() => {
      async function checkAdmin() {
        try {
          const isAdmin = await fetch("/lti/auth").then(data => data.json());
          (isAdmin.authorised === "true") && setIsAdmin(true);
        } catch {
          setIsAdmin(false);
        }
      }

      checkAdmin();
    })

    function handleTabChange(e, {index, id}) {
      setSelectedTab(index);
    }

    function compTab(option,index) {
      return (
        <Tabs.Panel id={option.value} renderTitle={option.label} isSelected={selectedTab === index}>
          {option.comp}
        </Tabs.Panel>
      )
    }

    const tabWidgets =
      <Tabs onRequestTabChange={handleTabChange}>
        {selectedOptions.filter((option) => option.value!== 'searchbar' && option.value!== 'libraryinfo').map((option,index) => {
          return compTab(option,index)
        })}
      </Tabs>;

    function allLibraryComponents() {
      return  (
        <>
          <View as="div" themeOverride={{}} >  
            {widgets.includes('searchbar') ? <Tewaharoa /> : ''}
            {widgets.includes('libraryinfo') ? <LibraryInfoSection /> : ''}
            {tabWidgets}
          </View>
        </>
      )
    }

    return (
      <>
        {/* <View as="p" margin="small none" padding="small none">Course code: {course} | Subjects: {subjectList}</View> */}
        {(isAdmin && place !== "link") ? <Reconfigure /> : ""}
        {(widgets.length > 1) ? allLibraryComponents() : selectedOptions[0].comp}
      </>
    )
}

  const notSetUp = <View as="p" margin="small none" padding="small none">Not set up</View>;
const settingUp = <Spinner margin="x-large" renderTitle="Setting up"/>;

export function Widgets() {

  const { course, subjects, setSubjects, widgets } = useContext(QueryDetails);
  const [gotSubs,setGotSubs] = useState(false);

  useEffect(() => {
    // Retrieve the subjects for the course

    async function fetchSubs() {
      const subs = await getSubs(course);
      setSubjects(subs);
      setGotSubs(true);
    }

    (course === "" || widgets.length === 0) || fetchSubs();

  },[]);

  // Display "Setting up..." while subjects are being retrieved
  const subjectResults = (!gotSubs)
    ? settingUp
    : <Results />;

  // Proceed if we have a course and selected widgets
  return (
    <>
    {(course === "" || widgets.length === 0) ? <NotSetUp /> : subjectResults}
    </>
  )
}