import {useContext, useEffect, useState} from "react";
import {Flex, Heading, IconExternalLinkLine, Link} from '@instructure/ui';
import {QueryDetails} from "./app";
import {AssetGrid, CatLink, Message, Pagination, useWindowWidth, Widget} from "./widget";

function processExams(results) {

    return results.docs.map(exam => {
        return {
            title: exam.pnx.display.title[0],
            author: exam.pnx.display.creator,
            url: exam.delivery.availabilityLinksUrl[0],
            date: exam.pnx.display.creationdate[0],
            recordid: exam.pnx.control.sourcerecordid[0],
        }
    }).sort((a, b) => b.date.localeCompare(a.date));
}

function genTWLink(course) {
    const TWUrl = "https://tewaharoa.victoria.ac.nz/discovery/search";
    const linkParams = {
        sortby: "date_d",
        query: `any,contains,${course.toLowerCase()}`,
        offset: "0",
        limit: "10",
        tab: "ExamPapers",
        search_scope: "ExamPapers",
        vid: "64VUW_INST:VUWNUI"

    }
    const params = new URLSearchParams(linkParams);
    const qString = params.toString();
    return decodeURIComponent(`${TWUrl}?${qString}`);
}

function GenResults({course, resData}) {

    const step = 6;
    const [range, setRange] = useState({start: 0, step: step, end: step - 1});

    function Item({result}) {
        const author = result.author === undefined ? "(no author listed)" : result.author.join("; ");
        return (
            <>
                <Heading as="h3" level="h4">{result.title}</Heading>
                <Flex direction="column">
                    <Flex.Item><strong>Date:</strong> {result.date}</Flex.Item>
                    <Flex.Item><strong>Author:</strong> {author}</Flex.Item>
                    <Flex.Item margin="small none none">{(result.link !== "")
                        ? <Link target="_blank" href={result.url} isWithinText={false}
                                renderIcon={<IconExternalLinkLine size="x-small"/>}>Go to exam</Link>
                        : <>Not available for access</>}</Flex.Item>
                </Flex>
            </>
        )
    }

    const width = useWindowWidth();
    const cols = Math.min(3, Math.floor(width / 400));
    const nodata = <Message>No exams are currently held in the Library for this course</Message>;
    const catLinks = [{name: course, link: resData.link}];

    return (
        <>
            <CatLink links={catLinks}/>
            <AssetGrid cols={cols} resData={resData.exams.slice(range.start, range.end + 1)} nodata={nodata}
                       Item={Item}/>
            <Pagination total={resData.exams.length} range={range} setRange={setRange}/>
        </>
    );
}

export function Exams({intro}) {

    const {course} = useContext(QueryDetails);
    const [resData, setResData] = useState(null);

    const api = process.env.REACT_APP_TW_API;
    const searchParams = {
        q: `any,contains,${course}`,
        sort: "date_d",
        offset: "0",
        limit: "99",
        tab: "ExamPapers",
        scope: "ExamPapers",
        vid: "64VUW_INST:VUWNUI"
    };
    const qString = new URLSearchParams(searchParams).toString();

    useEffect(() => {
        // Search exams on load

        const getData = async () => {

            try {

                const rawExams = await fetch(`${api}?${qString}`).then(data => data.json());
                const examsData = processExams(rawExams);
                const link = genTWLink(course);

                setResData({exams: examsData, link: link});
            } catch {
                setResData({error: "Cannot retrieve data"});
            }
        }

        getData();

    }, []);

    return (
        <Widget heading="Past exams" resData={resData}>
            {intro && <Message>{intro}</Message>}
            <GenResults course={course} resData={resData}/>
        </Widget>
    )
}
