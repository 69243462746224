import { Button, Link, List,Text, SVGIcon, View} from "@instructure/ui"
import { IconExternalLinkLine, IconEmailLine } from '@instructure/ui-icons'
import { phoneIcon } from "./phoneIcon"


function ViewButton({value, buttonMessage, icon, }) {

    return(
      <>
        <View display="inline-block" margin="xx-small xx-small xx-small none">   
          <Link href={value} target="_blank">    
            <Button renderIcon={icon} 
                withBackground={false} 
                color="primary"
                value={value}
              >
              {buttonMessage}
            </Button>
          </Link> 
        </View>
      </>
    )
  }

export default function LibraryInfoSection () {
    return (
      <>
        <View as="div">
          <ViewButton 
            value="https://www.wgtn.ac.nz/library"
            buttonMessage="Library website"
            icon={IconExternalLinkLine}/>

          <ViewButton 
            value="https://libguides.victoria.ac.nz/assignment-research-help"
            buttonMessage="Assignment research help guide"
            icon={IconExternalLinkLine}/>

          <ViewButton 
            value="https://www.wgtn.ac.nz/library/study-and-assignments/library-guides"
            buttonMessage="Library guides"
            icon={IconExternalLinkLine}/>

          <ViewButton 
            value="https://www.wgtn.ac.nz/library/study-and-assignments/book-a-group-study-room"
            buttonMessage="Book study room"
            icon={IconExternalLinkLine}/>

           <View display="inline-block" margin="xx-small"> 
            <List isUnstyled margin="0">
              <List.Item ><Link href="mailto:library@vuw.ac.nz" renderIcon={<IconEmailLine height="0.875rem" width="0.875rem" />}><Text size="small">library@vuw.ac.nz</Text></Link>  </List.Item>
              <List.Item ><Link href="tel:+6444636186"  renderIcon={<SVGIcon src={phoneIcon} height="0.875rem" width="0.875rem" />}><Text size="small">+64 4 463 6186</Text></Link></List.Item>
            </List>
           </View>
        </View>
      </>
    )
}