import { Button, Flex, Responsive, TextInput, View } from "@instructure/ui"
import { IconSearchLine } from '@instructure/ui-icons'
import { useState } from "react"
import backgroundImage from './pattern.png'


function SearchBarDiv({widthKey}) {
  const [searchKeyword, setSearchKeyword] = useState(null)
  
  function handleChange(e){
    setSearchKeyword(e.target.value)
  }
 
  function handleSubmit(e) {
    e.preventDefault()
    searchKeyword.length> 0 && window.open(`https://tewaharoa.victoria.ac.nz/discovery/search?query=any,contains,`+searchKeyword+`&tab=all&search_scope=MyInst_and_CI&vid=64VUW_INST:VUWNUI&offset=0`)
  }

  function button() { 
    if(widthKey==='large') return <Button type="submit" display="block"  renderIcon={IconSearchLine} withVisualDebug>Search</Button>;
    else if (widthKey ==='medium') return <Button type="submit" display="block" >Search</Button>;
    else if(widthKey === 'small') return <Button type="submit" display="block" renderIcon={IconSearchLine}></Button>;
    else return <Button type="submit" display="block" renderIcon={IconSearchLine}></Button>;
  }

  return (
    <View  as="div" padding="small 0" style={{backgroundImage: `url(${backgroundImage})`}} overflowY="auto" overflowX="auto">
      <View  as="form" onSubmit={handleSubmit} >
        <Flex alignItems="center" display="flex">
          <Flex.Item padding={ "small 0 small small"}  shouldShrink shouldGrow size="65%">
            <TextInput 
              onChange={handleChange}
              placeholder="Find books, articles, online resources…"
            />
          </Flex.Item >
          <Flex.Item  textAlign="start" shouldShrink shouldGrow padding="0 small" size="1%" >
            {button()}
          </Flex.Item>
        </Flex>
      </View>
    </View>
  )
}


export default function Tewaharoa() {
  
  return (
    <>
      <Responsive
        match="media"
        query={{
          large: { minWidth:  1024 },
          medium: { minWidth: 768 },
          small: { minWidth: 481},
          xsmall: { maxWidth: 480 }
        }}>
        {(props, matches) => {
          if(matches.includes('large')) {
            return(
              <SearchBarDiv widthKey="large"/>
          )} else if(matches.includes('medium') && !matches.includes('large')) {
            return(
              <SearchBarDiv widthKey="medium"/>
            )
          } else if(matches.includes('small') && !matches.includes('medium')) {
            return (
              <SearchBarDiv widthKey="small" />
            )
          } else 
            return (
              <SearchBarDiv widthKey="xsmall" />
            )
          }
        }
      </Responsive>
    </>
  )
}