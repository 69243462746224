import { useContext, useState } from "react";
import { Button, Checkbox, FormFieldGroup, Heading, ScreenReaderContent, Text, TextInput, View } from '@instructure/ui';
import { QueryDetails } from "./app";
import { verify } from "./search";
import { options } from "./widgets";

const ltiConfig = process.env.REACT_APP_POST_CONFIG;
const ltiDeeplink = process.env.REACT_APP_POST_LINK;
  
function ChoiceBox() {

  const { course, place } = useContext(QueryDetails);

  const [code, setCode] = useState(verify(course) ? course : "");
  const [valid,setValid] = useState(true);
  const [error,setError] = useState(null);
  {/* searchbar and libraryinfo are enabled by default in the config page. The subject librarians proposed this requirement to decrease the number of items that the course creator needs to select, allowing us to keep the modularity of the bento-box. */}
  const [selected, setSelected] = useState(() => place === 'link' ? [] : ['searchbar', 'libraryinfo']);

  const optionCheckboxes = options.map(({value,label,desc}) => 
    <Checkbox
      label={label}
      key={label}
      value={value}
      onChange={() => updateSelected(value)}
      checked={selected.includes(value)}
      messages={desc ? [{text: <View margin="small none none medium" padding="none none none x-small"><Text size="small" color="secondary">{desc}</Text></View>, type: "hint" }] : []}
    />
  );

  function updateSelected(value) {
    selected.indexOf(value) === -1
      ? setSelected(place === "link" ? [value] : [...selected,value])
      : setSelected(place === "link" ? [] : selected.filter(el => el !== value));
  }

  function getAll() {
    return options.map(option => option.value);
  }

  function handleCodeChange(e) {
    setCode(e.target.value);
    setValid(verify(e.target.value));
  }

  function handleCodeBlur() {
    valid ? setError(null) : setError([{text: "Enter valid course code", type: "error"}]);
  }

  return (
    <View as="form" action={place === "link" ? ltiDeeplink : ltiConfig} method="POST" margin="small none" padding="small none" >
      {verify(course) || <View as="p" margin="small none" padding="small none">Enter course code (e.g. LAWS212)</View>}
      <FormFieldGroup layout="inline" description={<ScreenReaderContent>Course code input form</ScreenReaderContent>} rowSpacing="small" vAlign="middle">
        <TextInput
          width="10em"
          renderLabel="Course code"
          name="course"
          value={code}
          onChange={handleCodeChange}
          placeholder="Enter course code"
          onBlur={handleCodeBlur}
          messages={error}
        />
      </FormFieldGroup>
      <View as="p" margin="small none" padding="small none">Select the Library resources widgets you want displayed for your course</View>
      <FormFieldGroup description={<ScreenReaderContent>Widget selection form</ScreenReaderContent>} >
        {(place === "course-nav") && 
          <Checkbox
            label="All"
            key="all"
            value="all"
            checked={selected.length === options.length}
            indeterminate={selected.length > 0 && selected.length < options.length}
            onChange={() => setSelected(selected.length === options.length ? [] : getAll())}
          />}
        {optionCheckboxes}
        <Button type="submit" value="Generate" disabled={selected.length === 0 || !valid}>Generate</Button>
      </FormFieldGroup>
      <input type="hidden" name="widgets" value={selected.join(",")}/>
    </View>
  )
}

export function Configure() {

  return (
    <>
    <Heading level="h1">Configure Library resources</Heading>
    <ChoiceBox />
    </>
  )
  
}