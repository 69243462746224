import React, {createContext, useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import {canvas, Heading, InstUISettingsProvider, View} from '@instructure/ui';
import {Configure} from "./configure";
import {Widgets} from "./widgets";
import {updateQuery} from "./query";

export const QueryDetails = createContext(null);

export function App({query, options, place}) {

    const [widgets, setWidgets] = useState((options.length !== 0) ? options : []);
    const [course, setCourse] = useState((query) ? query.toUpperCase() : "");
    const [subjects, setSubjects] = useState([]);

    useEffect(() => {
        course !== "" && updateQuery({course});
    }, [course])

    useEffect(() => {
        widgets.length !== 0 && updateQuery({widgets});
    }, [widgets])

    const heading = (widgets.length > 1) ?
        <Heading level="h1" margin="none none medium none">Library resources</Heading> : "";

    return (
        <React.StrictMode>
            <InstUISettingsProvider theme={canvas}>
                <QueryDetails.Provider value={{course, setCourse, subjects, setSubjects, widgets, setWidgets, place}}>
                    <View as="div" margin="small">
                        {heading}
                        <Routes>
                            <Route path="/widgets/v1/" element={<Widgets/>}/>
                            <Route path="/widgets/v1/configure" element={<Configure/>}/>
                            <Route path="/" element={<Widgets/>}/>
                            <Route path="/configure" element={<Configure/>}/>
                        </Routes>
                    </View>
                </QueryDetails.Provider>
            </InstUISettingsProvider>
        </React.StrictMode>
    )

}