import {useContext, useEffect, useState} from "react";
import {
    Flex,
    Heading,
    IconCalendarMonthLine,
    IconEmailLine,
    IconMarkerLine,
    IconSyllabusLine,
    Img,
    Link,
    List,
    Responsive,
    SVGIcon,
    Text,
    View
} from "@instructure/ui";
import {QueryDetails} from "./app";
import {Message, Widget} from "./widget";
import {phoneIcon} from "./phoneIcon";

function GenResults({resData}) {

    function GuidesList({guides}) {
        const resList = guides.map(guide => {
            return (<List.Item key={guide.slug}>
                <Link renderIcon={<IconSyllabusLine size="x-small"/>} href={guide.friendly_url} target="_blank"
                      isWithinText={false}>
                    {guide.name}
                </Link>
            </List.Item>)
        });

        const guideHeading = guides.length === 1 ? "Guide" : "Guides"

        return (<div>
            <Heading level="h3">{guideHeading}</Heading>
            {(guides.length !== 0) ? <List isUnstyled>{resList}</List> : <Text><p>No data</p></Text>}
        </div>)
    }

    function LibrariansList({librarians}) {
        const resList = librarians.map(librarian => {
            const img = <Img src={librarian.profile.image.url} alt="{name} profile-picture photograph"
                             display="inline-block"/>;
            const mailto = `mailto:${librarian.email}`;
            const tel = `tel:${librarian.profile.connect.phone}`;
            const name = `${librarian.first_name} ${librarian.last_name}`;
            const nameSlug = name.toLowerCase().replaceAll(" ", "-");
            // const subjectsLinks = librarian.subjects.map(subject => <Link key={subject.subject} href={subject.url} isWithinText={false} target="_blank">{subject.subject}</Link>).reduce((prev, curr) => [prev, ", ", curr]);
            const subjects = librarian.subjects.map(subject => subject.subject).reduce((prev, curr) => [prev, ", ", curr]);
            return (<List.Item key={nameSlug}>
                <Responsive
                    query={{small: {maxWidth: "40rem"}, large: {minWidth: "40rem"}}}
                    props={{
                        small: {direction: "column-reverse"}, large: {direction: "row"}
                    }}
                >
                    {(props, matches) => {
                        return (<Flex {...props} alignItems="start" wrap="no-wrap">
                            <Flex.Item
                                margin={matches.includes("large") ? "none small none none" : "small none none"}>{img}</Flex.Item>
                            <Flex.Item as="div" shouldShrink shouldGrow>
                                <Heading level="h3" as="h4">{name}</Heading>
                                <List isUnstyled>
                                    <List.Item key="subjects"><Text
                                        size="small"><strong>Subjects:</strong> {subjects}</Text></List.Item>
                                    <List.Item key="email"><Text><Link target="_blank" href={mailto}
                                                                       renderIcon={<IconEmailLine
                                                                           size="x-small"/>}
                                                                       isWithinText={false}>{librarian.email}</Link></Text></List.Item>
                                    {librarian.profile.connect.phone !== "" ?
                                        <List.Item key="phone"><Text><Link target="_blank" href={tel}
                                                                           renderIcon={<SVGIcon src={phoneIcon}
                                                                                                size="x-small"/>}
                                                                           isWithinText={false}>{librarian.profile.connect.phone}</Link></Text></List.Item> : ""}
                                    <List.Item key="appointment"><Text><Link href={librarian.profile.widget_lc}
                                                                             renderIcon={<IconCalendarMonthLine
                                                                                 size="x-small"/>}
                                                                             target="_blank"
                                                                             isWithinText={false}>Make an
                                        appointment with {name}</Link></Text></List.Item>
                                    {librarian.profile.connect.address !== "" ?
                                        <List.Item key="address"><Text><View as="span"
                                                                             padding="none xx-small none none"><IconMarkerLine
                                            size="x-small"/></View>{librarian.profile.connect.address}
                                        </Text></List.Item> : ""}
                                </List>
                            </Flex.Item>
                        </Flex>)
                    }}
                </Responsive>
            </List.Item>)
        });

        const libHeading = librarians.length === 1 ? "Librarian" : "Librarians";

        return (<div>
            <Heading level="h3">{libHeading}</Heading>
            {(librarians.length !== 0) ? <List isUnstyled itemSpacing="medium">{resList}</List> :
                <Text><p>No data</p></Text>}
        </div>)
    }

    return (<Responsive
        query={{small: {maxWidth: "60rem"}, large: {minWidth: "60rem"}}}
        props={{
            small: {direction: "column",}, large: {direction: "row"}
        }}
    >
        {(props, matches) => {
            return (<Flex {...props} alignItems="start" wrap="no-wrap" margin="none none medium none">
                <Flex.Item
                    margin={matches.includes("large") ? "none medium none none" : "none none medium none"}
                    width="12.5rem"><GuidesList guides={resData.guides}/></Flex.Item>
                <Flex.Item shouldShrink shouldGrow><LibrariansList librarians={resData.librarians}/></Flex.Item>
            </Flex>)
        }}
    </Responsive>);
}

export function Guide({intro}) {

    const {subjects} = useContext(QueryDetails);
    const [resData, setResData] = useState(null);

    const gApi = process.env.REACT_APP_GUIDE_API;
    const gSearchParams = {
        guide_types: "3", status: "1", expand: "owner,tags"
    };
    const gQString = new URLSearchParams(gSearchParams).toString();

    const lApi = process.env.REACT_APP_LIB_API;
    const lSearchParams = {
        expand: "profile"
    };
    const lQString = new URLSearchParams(lSearchParams).toString();

    useEffect(() => {

        const getData = async () => {

            try {

                if (subjects.length === 0) {
                    console.error("Could not search for guides: no subjects");
                    setResData({error: "Cannot retrieve data"});
                }

                // Get guide data
                const rawData = await fetch(`${gApi}?${gQString}`);
                const jsonData = await rawData.json();
                const guideData = jsonData.filter(guide => {
                    return subjects.map(subject => subject.slug).includes(guide.slug);
                });

                // Get librarians from guide data
                const libData = (await Promise.all(guideData.map(guide => {
                    return fetch(`${lApi}/?owner-id=${guide.owner_id}&${lQString}`).then(data => data.json());
                }))).flat().filter((value, index, self) => index === self.findIndex(t => t.email === value.email));

                libData.forEach(librarian => {
                    const libSubs = jsonData.filter(guide => {
                        // return guide.owner_id.toString() === librarian.id;
                        return guide.owner_id === librarian.id;
                    });
                    librarian.subjects = libSubs
                        .filter(subject => subject.friendly_url !== "")
                        .map((subject) => {
                            return {subject: subject.name, url: subject.friendly_url};
                        });
                })

                setResData({guides: guideData, librarians: libData});
            } catch {
                console.error("Error retrieving guides");
                setResData({error: "Cannot retrieve data"});
            }
        }

        getData();

    }, []);

    return (<Widget heading="Subject guide and librarian" resData={resData}>
        {intro && <Message>{intro}</Message>}
        <GenResults resData={resData}/>
    </Widget>)
}