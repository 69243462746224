import React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {App} from "./app";
import {getParams} from "./query";

const url = new URL(window.location.href);
const [query, options, place] = getParams(url);

if (module.hot) {
    module.hot.accept();
}

const app = document.getElementById("app");
const root = createRoot(app);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App query={query} options={options} place={place}/>
        </BrowserRouter>
    </React.StrictMode>
);