import {useContext, useEffect, useState} from "react";
import {Heading, IconExternalLinkLine, Link, Text} from '@instructure/ui';
import {QueryDetails} from "./app";
import {AssetGrid, Message, Pagination, useWindowWidth, Widget} from "./widget";
import {sanitize} from "dompurify";

const proxyUrl = "https://go.openathens.net/redirector/wgtn.ac.nz?url=";

function orderItems(items) {
    const itemList = items.filter((item, index, self) =>
            // Remove duplicates
            index === self.findIndex((alt) => (
                alt.name === item.name
            ))
    )
    itemList.sort((a, b) =>
        a.name.localeCompare(b.name));
    return itemList;
}

function getKeys(subjects, data) {

    const keyDBs = subjects.map((subject) => {
        const typeMatch = `KEY ${subject.name.toUpperCase()}`;
        const toCheck = data.filter(result => result.az_types);
        return toCheck.filter((result) => {
            const keyTypes = result.az_types.map(type => type.name.toUpperCase());
            return keyTypes.includes(typeMatch)
        });
    });
    return orderItems(keyDBs.flat());
}

function GenResults({resData}) {

    const step = 6;
    const [range, setRange] = useState({start: 0, step: step, end: step - 1});

    function Item({result}) {

        function description(desc) {
            const firstPara = desc.split("<br>")[0].split("</br>")[0].split("<br/>")[0].split("<br />")[0];
            const clean = sanitize(firstPara);
            return {__html: clean};
            // return firstPara;
        }

        function proxyLink(url, isProxied) {
            return (isProxied === 1 ? `${proxyUrl}${encodeURIComponent(url)}` : url)
        }

        return (
            <>
                <Heading level="h3"><Link target="_blank" href={proxyLink(result.url, result.meta.enable_proxy)}
                                          isWithinText={false}>{result.name}</Link></Heading>
                <Text as="p" size="small" lineHeight="default">{(result.description)
                    ? <><strong>Description:</strong> <Text as="span"
                                                            dangerouslySetInnerHTML={description(result.description)}/></>
                    : <>No description available</>}</Text>
                <Text as="p">{(result.url !== "")
                    ? <><Link target="_blank" href={proxyLink(result.url, result.meta.enable_proxy)}
                              isWithinText={false} renderIcon={<IconExternalLinkLine size="x-small"/>}>Go to
                        resource</Link></>
                    : <>Not available for access</>}</Text>
            </>
        )
    }

    const width = useWindowWidth();
    const cols = Math.min(3, Math.floor(width / 400));
    const nodata = <p>No data</p>;

    return (
        <>
            <AssetGrid cols={cols} resData={resData.slice(range.start, range.end + 1)} nodata={nodata} Item={Item}/>
            <Pagination total={resData.length} range={range} setRange={setRange}/>
        </>
    )
}

export function Key({intro}) {

    const {subjects} = useContext(QueryDetails);
    const [resData, setResData] = useState(null);

    const api = process.env.REACT_APP_KEY_API;
    const searchParams = {
        asset_types: "10",
        expand: "az_props,az_types"
    };
    const qString = new URLSearchParams(searchParams).toString();

    useEffect(() => {

        const getData = async () => {

            try {

                if (subjects.length === 0) {
                    console.error("Could not retrieve key resources: no subjects");
                    setResData({error: "Cannot retrieve data"});
                }

                const keyData = await fetch(`${api}?${qString}`).then(data => data.json());
                const keys = getKeys(subjects, keyData);
                setResData(keys);
            } catch {
                console.error("Error retrieving key resources");
                setResData({error: "Cannot retrieve data"});
            }
        }

        getData();

    }, []);

    return (

        <Widget heading="Key resources" resData={resData}>
            {intro && <Message>{intro}</Message>}
            <GenResults resData={resData}/>
        </Widget>
    )
}