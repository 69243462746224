import {useContext, useEffect, useState} from "react";
import {CloseButton, Heading, IconExternalLinkLine, Link, Modal, Text, TruncateText} from '@instructure/ui';
import {QueryDetails} from "./app";
import {subjectDetails} from "./subject-details";
import {AssetGrid, CatLink, Message, Pagination, useWindowWidth, Widget} from "./widget";

function processDatabases(items) {
    // Single array for each subject, without search metadata
    const allSubjects = items.map(list => list.docs).flat();

    // Three steps: 1. remove duplicates, 2. simplify record, 3. order alphabetically
    return allSubjects.filter((database, index, list) => {
        const title = database.pnx.display.title[0];
        return index === list.findIndex(alt => {
            const altTitle = alt.pnx.display.title[0];
            return altTitle === title;
        });
    }).map(database => {
        return {
            title: database.pnx.display.title[0],
            description: database.pnx.display.description ? database.pnx.display.description[0] : "",
            link: `https://victoria.alma.exlibrisgroup.com/view/uresolver/64VUW_INST/openurl?svc_dat=viewit&rft.mms_id=${database.pnx.control.sourcerecordid}`,
            twlink: `https://tewaharoa.victoria.ac.nz/discovery/fulldisplay?vid=64VUW_INST:VUWNUI&lang=en&search_scope=MyInst_and_CI&tab=all&docid=${database.pnx.control.recordid}`
        }
    }).sort((a, b) => a.title.localeCompare(b.title));
}

function genTWLink(subSlug) {
    const TWUrl = "https://tewaharoa.victoria.ac.nz/discovery/dbsearch";
    const linkParams = {
        sortby: "title",
        query: "contains,dbcategory,",
        databases: `category,${subjectDetails[subSlug].area}─${subjectDetails[subSlug].subject}`,
        offset: "0",
        limit: "10",
        tab: "jsearch_slot",
        vid: "64VUW_INST:VUWNUI"

    }
    const params = new URLSearchParams(linkParams);
    const qString = params.toString();
    return decodeURIComponent(`${TWUrl}?${qString}`).replace("+&+", "%20%26%20");
}

function GenResults({resData}) {

    const step = 6;
    const [range, setRange] = useState({start: 0, step: step, end: step - 1});

    function Item({result}) {

        function ReadMore({children}) {

            const [open, setOpen] = useState(false);

            function handleClick() {
                setOpen(!open);
            }

            const closeButton = <CloseButton
                placement="end"
                offset="small"
                onClick={() => setOpen(false)}
                screenReaderLabel="Close"
            />;

            return (<>
                <Link onClick={handleClick}>{children}</Link>
                <Modal
                    as="div"
                    open={open}
                    onDismiss={() => {
                        setOpen(false)
                    }}
                    size="medium"
                    label={result.title}
                    shouldCloseOnDocumentClick
                >
                    <Modal.Header>
                        {closeButton}
                        <Heading>{result.title}</Heading>
                    </Modal.Header>
                    <Modal.Body>
                        <Text lineHeight="double">{result.description}</Text>
                    </Modal.Body>
                </Modal>
            </>)
        }

        const [truncated, setTruncated] = useState(false);

        function handleUpdate(isTruncated) {
            (truncated !== isTruncated) && setTruncated(isTruncated);
        }

        const renderDescription = <>
            <TruncateText maxLines={6} onUpdate={handleUpdate} truncate="word">
                <strong>Description:</strong> {result.description}
            </TruncateText>
            {(truncated) && <ReadMore>...read more</ReadMore>}
        </>;

        return (<>
            <Heading level="h3"><Link target="_blank" href={result.link}
                                      isWithinText={false}>{result.title}</Link></Heading>
            <Text as="p" size="small" lineHeight="default">{(result.description) ? <>{renderDescription}</> : <>No
                description available</>}</Text>
            <p>{(result.link !== "") ? <><Link target="_blank" href={result.link} isWithinText={false}
                                               renderIcon={<IconExternalLinkLine size="x-small"/>}>Go to
                database</Link></> : <>Not available for access</>}</p>
        </>)
    }

    const width = useWindowWidth();
    const cols = Math.min(3, Math.floor(width / 400));
    const nodata = <Text as="p">No data</Text>;
    const catLinks = resData.links;

    return (<>
        <CatLink links={catLinks}/>
        <AssetGrid cols={cols} resData={resData.databases.slice(range.start, range.end + 1)} nodata={nodata}
                   Item={Item}/>
        <Pagination total={resData.databases.length} range={range} setRange={setRange}/>
    </>);
}

export function Databases({intro}) {

    const {subjects} = useContext(QueryDetails);
    const [resData, setResData] = useState(null);

    const api = process.env.REACT_APP_TW_API;
    const searchParams = {
        q: "contains,dbcategory,",
        sort: "title",
        offset: "0",
        limit: "99",
        tab: "jsearch_slot",
        search_scope: "MyInst_and_CI",
        vid: "64VUW_INST:VUWNUI"
    };

    useEffect(() => {

        const getData = async () => {

            try {
                if (subjects.length === 0) {
                    console.error("No subjects to search for")
                    setResData({error: "Cannot retrieve data"});
                    return;
                }
                // Get databases for each subject
                const rawData = await Promise.all(subjects
                    .filter(subject => subject.slug != null)
                    .map(subject => {
                        const subArea = subjectDetails[subject.slug].area;
                        const subSubject = subjectDetails[subject.slug].subject;
                        searchParams.databases = `category,${subArea}─${subSubject}`;
                        const qString = new URLSearchParams(searchParams).toString();
                        return fetch(`${api}?${qString}`).then(data => data.json());
                    }));
                const dbData = processDatabases(rawData);
                const links = subjects.map(subject => {
                    return {term: subject.slug, name: subject.name, link: genTWLink(subject.slug)}
                });

                setResData({databases: dbData, links: links});
            } catch (err) {
                console.error("Error retrieving databases")
                console.error(err)
                setResData({error: "Cannot retrieve data"});
            }
        }
        getData();
    }, []);

    return (

        <Widget heading="Databases" resData={resData}>
            {intro && <Message>{intro}</Message>}
            <GenResults resData={resData}/>
        </Widget>)
}