import {useContext, useEffect, useState} from "react";
import {Flex, Heading, IconExternalLinkLine, Link} from '@instructure/ui';
import {QueryDetails} from "./app";
import {AssetGrid, CatLink, Message, Pagination, useWindowWidth, Widget} from "./widget";

function processReserves(results) {

    return results.docs.map(reserve => {
        return {
            title: reserve.pnx.display.title[0],
            type: reserve.pnx.addata.format,
            edition: reserve.pnx.display.edition ? reserve.pnx.display.edition[0] : "",
            author: reserve.pnx.sort.author,
            recordid: reserve.pnx.display.mms[0],
            holdings: reserve.delivery.holding ? reserve.delivery.holding.map(holding => {
                return {
                    avail: holding.availabilityStatus,
                    loc: holding.mainLocation,
                    subLoc: holding.subLocation,
                    callN: holding.callNumber
                }
            }) : [],
            bestlocation: reserve.delivery.bestlocation ? {
                avail: reserve.delivery.bestlocation.availabilityStatus,
                loc: reserve.delivery.bestlocation.mainLocation,
                subLoc: reserve.delivery.bestlocation.subLocation,
                callN: reserve.delivery.bestlocation.callNumber
            } : {}
        }
    }).sort((a, b) => a.title.localeCompare(b.title));
}

function genTWLink(course) {
    const TWUrl = "https://tewaharoa.victoria.ac.nz/discovery/search";
    const linkParams = {
        sortby: "date_d",
        query: `any,contains,${course.toLowerCase()}`,
        offset: "0",
        limit: "10",
        tab: "CourseReserves",
        search_scope: "CourseReserves",
        vid: "64VUW_INST:VUWNUI"

    }
    const params = new URLSearchParams(linkParams);
    const qString = params.toString();
    return decodeURIComponent(`${TWUrl}?${qString}`);
}

function GenResults({course, resData}) {

    const step = 6;
    const [range, setRange] = useState({start: 0, step: step, end: step - 1});

    function Item({result}) {
        const author = result.author !== null ? result.author : "No author details available";
        const availability = result.bestlocation.avail !== null ? <>
            <em>{result.bestlocation.avail}</em> at {result.bestlocation.loc}</> : "";
        const reserveLink = `https://tewaharoa.victoria.ac.nz/discovery/fulldisplay?vid=64VUW_INST:VUWNUI&lang=en&search_scope=CourseReserves&tab=CourseResearves&docid=alma${result.recordid}`;
        const recordID = result.recordid !== null ? <Link href={reserveLink} target="_blank" isWithinText={false}
                                                          renderIcon={<IconExternalLinkLine size="x-small"/>}>View
            record in Te Waharoa</Link> : "";
        return (<>
            <Heading as="h3" level="h4">{result.title}</Heading>
            <Flex direction="column">
                <Flex.Item>{author}</Flex.Item>
                <Flex.Item>{availability}</Flex.Item>
                <Flex.Item>{result.bestlocation.callN}</Flex.Item>
                <Flex.Item margin="small none none">{recordID}</Flex.Item>
            </Flex>
        </>)
    }

    const width = useWindowWidth();
    const cols = Math.min(3, Math.floor(width / 400));
    const nodata = <Message>No reserves currently held for this course</Message>;
    const catLinks = [{name: course, link: resData.link}];

    return (<>
        <CatLink links={catLinks}/>
        <AssetGrid cols={cols} resData={resData.reserves.slice(range.start, range.end + 1)} nodata={nodata}
                   Item={Item}/>
        <Pagination total={resData.reserves.length} range={range} setRange={setRange}/>
    </>);
}

export function Reserves({intro}) {

    const {course} = useContext(QueryDetails);
    const [resData, setResData] = useState(null);

    const api = process.env.REACT_APP_TW_API;
    const searchParams = {
        q: `any,contains,${course}`,
        sort: "rank",
        offset: "0",
        limit: "99",
        tab: "CourseReserves",
        scope: "CourseReserves",
        vid: "64VUW_INST:VUWNUI",
    };
    const qString = new URLSearchParams(searchParams).toString();

    useEffect(() => {
        // Search reserves on load

        const getData = async () => {

            try {

                const rawReserves = await fetch(`${api}?${qString}`).then(data => data.json());
                const reservesData = processReserves(rawReserves);
                const link = genTWLink(course);

                setResData({reserves: reservesData, link: link});

            } catch {
                console.error("Error retrieving course reserves");
                setResData({error: "Cannot retrieve data"});
            }
        }

        getData();

    }, []);

    return (

        <Widget heading="Course reserves" resData={resData}>
            {intro && <Message>{intro}</Message>}
            <GenResults course={course} resData={resData}/>
        </Widget>)
}