export const subjectDetails = {
    "accounting": {
        "link": "https://libguides.victoria.ac.nz/accounting",
        "subject": "Accounting",
        "area": "Business & Government",
        "guide-id": "921252"
    },
    "anthropology": {
        "link": "https://libguides.victoria.ac.nz/anthropology",
        "subject": "Anthropology",
        "area": "Humanities & Social Science",
        "guide-id": "921180"
    },
    "architecture": {
        "link": "https://libguides.victoria.ac.nz/architecture",
        "subject": "Architecture",
        "area": "Architecture & Design",
        "guide-id": "921179"
    },
    "art-history": {
        "link": "https://libguides.victoria.ac.nz/art-history",
        "subject": "Art History",
        "area": "Humanities & Social Science",
        "guide-id": "921196"
    },
    "asian-studies": {
        "link": "https://libguides.victoria.ac.nz/asian-studies",
        "subject": "Asian Studies",
        "area": "Humanities & Social Science",
        "guide-id": "921195"
    },
    "biological-sciences": {
        "link": "https://libguides.victoria.ac.nz/biological-sciences",
        "subject": "Biological Sciences",
        "area": "Biology & Health",
        "guide-id": "921546"
    },
    "biomedical-sciences": {
        "link": "https://libguides.victoria.ac.nz/biomedical-sciences",
        "subject": "Biomedical Sciences",
        "area": "Biology & Health",
        "guide-id": "921549"
    },
    "chemistry": {
        "link": "https://libguides.victoria.ac.nz/chemistry",
        "subject": "Chemistry",
        "area": "Science",
        "guide-id": "921182"
    },
    "chinese": {
        "link": "https://libguides.victoria.ac.nz/chinese",
        "subject": "Chinese",
        "area": "Languages & Linguistics",
        "guide-id": "921208"
    },
    "classics": {
        "link": "https://libguides.victoria.ac.nz/classics",
        "subject": "Classics",
        "area": "Humanities & Social Science",
        "guide-id": "921712"
    },
    "climate-environment": {
        "link": "https://libguides.victoria.ac.nz/climate-environment",
        "subject": "Climate and the Environment",
        "area": "Humanities & Social Science",
        "guide-id": "921756"
    },
    "commercial-law": {
        "link": "https://libguides.victoria.ac.nz/commercial-law",
        "subject": "Commercial Law",
        "area": "Business & Government",
        "guide-id": "921253"
    },
    "computer-science": {
        "link": "https://libguides.victoria.ac.nz/computer-science",
        "subject": "Computer Science",
        "area": "Engineering & Computer Science",
        "guide-id": "921262"
    },
    "criminology": {
        "link": "https://libguides.victoria.ac.nz/criminology",
        "subject": "Criminology",
        "area": "Humanities & Social Science",
        "guide-id": "921192"
    },
    "design": {
        "link": "https://libguides.victoria.ac.nz/design",
        "subject": "Design",
        "area": "Architecture & Design",
        "guide-id": "921216"
    },
    "development-studies": {
        "link": "https://libguides.victoria.ac.nz/development-studies",
        "subject": "Development Studies",
        "area": "Humanities & Social Science",
        "guide-id": "921628"
    },
    "earth-sciences": {
        "link": "https://libguides.victoria.ac.nz/earth-sciences",
        "subject": "Earth Sciences",
        "area": "Science",
        "guide-id": "921453"
    },
    "economics-finance": {
        "link": "https://libguides.victoria.ac.nz/economics-finance",
        "subject": "Economics and Finance",
        "area": "Business & Government",
        "guide-id": "921352"
    },
    "education": {
        "link": "https://libguides.victoria.ac.nz/education",
        "subject": "Education",
        "area": "Education",
        "guide-id": "921242"
    },
    "engineering": {
        "link": "https://libguides.victoria.ac.nz/engineering",
        "subject": "Engineering",
        "area": "Engineering & Computer Science",
        "guide-id": "921002"
    },
    "english-literature": {
        "link": "https://libguides.victoria.ac.nz/english-literature",
        "subject": "English Literatures &amp; Creative Communication",
        "area": "Humanities & Social Science",
        "guide-id": "920975"
    },
    "film": {
        "link": "https://libguides.victoria.ac.nz/film",
        "subject": "Film",
        "area": "Humanities & Social Science",
        "guide-id": "921558"
    },
    "french": {
        "link": "https://libguides.victoria.ac.nz/french",
        "subject": "French",
        "area": "Languages & Linguistics",
        "guide-id": "921339"
    },
    "geography": {
        "link": "https://libguides.victoria.ac.nz/geography",
        "subject": "Geography",
        "area": "Humanities & Social Science",
        "guide-id": "921215"
    },
    "german": {
        "link": "https://libguides.victoria.ac.nz/german",
        "subject": "German",
        "area": "Languages & Linguistics",
        "guide-id": "921346"
    },
    "health": {
        "link": "https://libguides.victoria.ac.nz/health",
        "subject": "Health",
        "area": "Biology & Health",
        "guide-id": "921119"
    },
    "history": {
        "link": "https://libguides.victoria.ac.nz/history",
        "subject": "History",
        "area": "Humanities & Social Science",
        "guide-id": "921213"
    },
    "human-resource-management": {
        "link": "https://libguides.victoria.ac.nz/human-resource-management",
        "subject": "Human Resource Management",
        "area": "Business & Government",
        "guide-id": "921255"
    },
    "information-studies": {
        "link": "https://libguides.victoria.ac.nz/information-studies",
        "subject": "Information Studies",
        "area": "Business & Government",
        "guide-id": "921185"
    },
    "information-systems": {
        "link": "https://libguides.victoria.ac.nz/information-systems",
        "subject": "Information Systems",
        "area": "Business & Government",
        "guide-id": "921354"
    },
    "international-business": {
        "link": "https://libguides.victoria.ac.nz/international-business",
        "subject": "International Business",
        "area": "Business & Government",
        "guide-id": "921257"
    },
    "italian": {
        "link": "https://libguides.victoria.ac.nz/italian",
        "subject": "Italian",
        "area": "Languages & Linguistics",
        "guide-id": "921801"
    },
    "japanese": {
        "link": "https://libguides.victoria.ac.nz/japanese",
        "subject": "Japanese",
        "area": "Languages & Linguistics",
        "guide-id": "921809"
    },
    "law": {
        "link": "https://libguides.victoria.ac.nz/law",
        "subject": "Law",
        "area": "Law",
        "guide-id": "920862"
    },
    "linguistics": {
        "link": "https://libguides.victoria.ac.nz/linguistics",
        "subject": "Linguistics and Applied Linguistics",
        "area": "Languages & Linguistics",
        "guide-id": "921177"
    },
    "management": {
        "link": "https://libguides.victoria.ac.nz/management",
        "subject": "Management",
        "area": "Business & Government",
        "guide-id": "921553"
    },
    "marketing": {
        "link": "https://libguides.victoria.ac.nz/marketing",
        "subject": "Marketing",
        "area": "Business & Government",
        "guide-id": "921258"
    },
    "mathematics": {
        "link": "https://libguides.victoria.ac.nz/mathematics",
        "subject": "Mathematics",
        "area": "Engineering & Computer Science",
        "guide-id": "921263"
    },
    "media-studies": {
        "link": "https://libguides.victoria.ac.nz/media-studies",
        "subject": "Media Studies and Communication",
        "area": "Humanities & Social Science",
        "guide-id": "921176"
    },
    "museum-heritage-studies": {
        "link": "https://libguides.victoria.ac.nz/museum-heritage-studies",
        "subject": "Museum and Heritage Studies",
        "area": "Humanities & Social Science",
        "guide-id": "921273"
    },
    "music": {
        "link": "https://libguides.victoria.ac.nz/music",
        "subject": "Music",
        "area": "Humanities & Social Science",
        "guide-id": "921181"
    },
    "maori-studies": {
        "link": "https://libguides.victoria.ac.nz/maori-studies",
        "subject": "Māori Studies - Te Ao Māori",
        "area": "Māori & Pasifika",
        "guide-id": "921184"
    },
    "nursing-midwifery-health": {
        "link": "https://libguides.victoria.ac.nz/nursing-midwifery-health",
        "subject": "Nursing, Midwifery, and Health Practice",
        "area": "Biology & Health",
        "guide-id": "921451"
    },
    "pacific-studies": {
        "link": "https://libguides.victoria.ac.nz/pacific-studies",
        "subject": "Pacific Studies",
        "area": "Māori & Pasifika",
        "guide-id": "921178"
    },
    "philosophy": {
        "link": "https://libguides.victoria.ac.nz/philosophy",
        "subject": "Philosophy",
        "area": "Humanities & Social Science",
        "guide-id": "921209"
    },
    "physics": {
        "link": "https://libguides.victoria.ac.nz/physics",
        "subject": "Physics",
        "area": "Science",
        "guide-id": "921193"
    },
    "political-science": {
        "link": "https://libguides.victoria.ac.nz/political-science",
        "subject": "Political Science and International Relations",
        "area": "Humanities & Social Science",
        "guide-id": "921250"
    },
    "psychology": {
        "link": "https://libguides.victoria.ac.nz/psychology",
        "subject": "Psychology",
        "area": "Biology & Health",
        "guide-id": "921554"
    },
    "public-policy-public-management": {
        "link": "https://libguides.victoria.ac.nz/public-policy-public-management",
        "subject": "Public Policy and Public Management",
        "area": "Business & Government",
        "guide-id": "921353"
    },
    "religious-studies": {
        "link": "https://libguides.victoria.ac.nz/religious-studies",
        "subject": "Religious Studies",
        "area": "Humanities & Social Science",
        "guide-id": "921290"
    },
    "samoan-studies": {
        "link": "https://libguides.victoria.ac.nz/samoan-studies",
        "subject": "Samoan Studies",
        "area": "Māori & Pasifika",
        "guide-id": "921843"
    },
    "science-society": {
        "link": "https://libguides.victoria.ac.nz/science-society",
        "subject": "Science in Society",
        "area": "Humanities & Social Science",
        "guide-id": "921758"
    },
    "sociology": {
        "link": "https://libguides.victoria.ac.nz/sociology",
        "subject": "Sociology",
        "area": "Humanities & Social Science",
        "guide-id": "921465"
    },
    "spanish": {
        "link": "https://libguides.victoria.ac.nz/spanish",
        "subject": "Spanish",
        "area": "Languages & Linguistics",
        "guide-id": "922055"
    },
    "statistics": {
        "link": "https://libguides.victoria.ac.nz/statistics",
        "subject": "Statistics",
        "area": "Engineering & Computer Science",
        "guide-id": "921265"
    },
    "theatre": {
        "link": "https://libguides.victoria.ac.nz/theatre",
        "subject": "Theatre and Performance Studies",
        "area": "Humanities & Social Science",
        "guide-id": "921571"
    },
    "tourism": {
        "link": "https://libguides.victoria.ac.nz/tourism",
        "subject": "Tourism Management",
        "area": "Business & Government",
        "guide-id": "921260"
    },

}