import {
    Button,
    Flex,
    Grid,
    Heading,
    IconExternalLinkLine,
    IconWarningLine,
    Link,
    Responsive,
    Spinner,
    Text,
    View
} from '@instructure/ui';
import {useLayoutEffect, useState} from 'react';

export function AssetGrid({cols, resData, nodata, Item}) {

    let resArrays = []
    for (let i = 0; i < resData.length; i += cols) {
        resArrays.push(resData.slice(i, i + cols));
    }
    const colWidth = 12 / cols;

    const resList =
        <Grid margin="none none medium none" colSpacing="medium" rowSpacing="medium">
            {resArrays.map((resArray, index) => {
                return (
                    <Grid.Row key={index}>
                        {resArray.map(result => {
                            return (
                                <Grid.Col width={colWidth}>
                                    <Item result={result}/>
                                </Grid.Col>
                            );
                        })}
                    </Grid.Row>
                )
            })}
        </Grid>;

    return (
        <View as="div">
            {(resData.length !== 0)
                ? resList
                : nodata}
        </View>
    )
}

export function CatLink({links}) {

    const catLinks = links.map(link =>
        <Link key={link.name} href={link.link} target="_blank" isWithinText={false}>{link.name}</Link>
    ).reduce((prev, curr) => [prev, ", ", curr]);

    return (
        <View as="p" margin="none none medium none">
            {(links.length === 1)
                ? <Link href={links[0].link} target="_blank" isWithinText={false}
                        renderIcon={<IconExternalLinkLine size="x-small"/>}>View search for {links[0].name} in Te
                    Waharoa</Link>
                : <>View search by subject in Te Waharoa: <View as="span" margin="none x-small"><IconExternalLinkLine
                    size="x-small"/></View>{catLinks}</>}
        </View>
    );
}

// export function AssetGrid({ cols,resData,nodata,Item }) {

//   const resList = 
//     <>{resData.map((result, index) => {
//         return (
//           <Flex.Item key={index} size="400px" shouldGrow shouldShrink padding="small" margin="small">
//             <Item result={result}/>
//           </Flex.Item>
//         );
//     })}</>;

//   return (
//     <>
//     {(resData.length !== 0)
//       // ? <List isUnstyled><Grid><ResList/></Grid></List>
//       ? <Flex wrap="wrap" alignItems="start">{resList}</Flex>
//       : <>{nodata}</>
//     }
//     </>
//   )
// }

export function Pagination({total, range, setRange}) {
    const backDisabled = (range.start <= 0) ? "disabled" : "enabled";
    const forwardDisabled = (range.end + 1 >= total) ? "disabled" : "enabled";
    const buttons =
        <Responsive
            query={{small: {maxWidth: "30rem"}, large: {minWidth: "30rem"}}}
            props={{
                small: {direction: "column"},
                large: {direction: "row"}
            }}
        >
            {(props, matches) => {
                return (
                    <Flex {...props} margin="medium none none">
                        <Flex.Item overflowY="visible"><Button interaction={backDisabled} onClick={() => setRange({
                            start: range.start - range.step,
                            step: range.step,
                            end: range.end - range.step
                        })}
                                                               margin={matches.includes("large") ? "none medium none none" : "none none small none"}>back</Button></Flex.Item>
                        <Flex.Item>Showing {range.start + 1} to {Math.min(range.end + 1, total)} of {total}</Flex.Item>
                        <Flex.Item overflowY="visible"><Button interaction={forwardDisabled} onClick={() => setRange({
                            start: range.start + range.step,
                            step: range.step,
                            end: range.end + range.step
                        })}
                                                               margin={matches.includes("large") ? "none none none medium" : "small none none none"}>forward</Button></Flex.Item>
                    </Flex>
                )
            }}
        </Responsive>;
    return (
        <>{(total > range.step) && buttons}</>
    )
}

export function Message({children}) {
    return (
        <View as="p" margin="none none medium none">{children}</View>
    );
}

export function useWindowWidth() {
    const [width, setWidth] = useState();
    useLayoutEffect(() => {
        function updateWidth() {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", updateWidth);
        updateWidth();
        return () => window.removeEventListener("resize", updateWidth);
    }, []);
    return width;
}

export function Widget({heading, resData, children}) {

    const loading = <Spinner margin="medium" renderTitle="Loading"/>;

    return (
        <View as="section">
            <Heading as="h2" margin="medium none">{heading}</Heading>
            {(resData === null)
                ? <>{loading}</>
                : (resData.error)
                    ? <View as="div"><Text as="p" color="danger"><View as="span"
                                                                       padding="none small none none"><IconWarningLine
                        size="small" color="error"/></View>{resData.error}</Text></View>
                    : <>{children}</>}
        </View>
    );
}