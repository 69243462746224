import {useContext, useEffect, useState} from "react";
import {IconExternalLinkLine, Link, List, Text, View} from '@instructure/ui';
import {QueryDetails} from "./app";
import {Message, Widget} from "./widget";

function GenResults({resData}) {

    const linkList = resData.sort((a, b) => a.subject.localeCompare(b.subject)).map(subject => {
        const link = subject.subject === "Law" ? "https://libguides.victoria.ac.nz/law/key-resources#s-lg-box-21145549" : `${subject.url}/referencing`;
        return (<List.Item key={subject.subject}>
            <Link href={link} target="_blank" renderIcon={<IconExternalLinkLine size="x-small"/>}
                  isWithinText={false}>{subject.subject}</Link>
        </List.Item>)
    });
    return (<View as="div">
        <List isUnstyled={true}>
            {linkList}
        </List>
        <Text as="p">For general information on referencing, see the <Link target="_blank"
                                                                           href="https://libguides.victoria.ac.nz/referencing-citing"
                                                                           renderIcon={<IconExternalLinkLine
                                                                               size="x-small"/>}>Referencing and
            citing</Link> library guide.</Text>
    </View>)
}

export function Referencing({intro}) {

    const {subjects} = useContext(QueryDetails);
    const [resData, setResData] = useState(null);

    const gApi = process.env.REACT_APP_GUIDE_API;
    const gSearchParams = {
        guide_types: "3", status: "1", expand: "owner,tags,pages"
    };
    const gQString = new URLSearchParams(gSearchParams).toString();

    useEffect(() => {
        // Search for reference information on load

        const getData = async () => {

            try {

                if (subjects.length === 0) {
                    console.error("Could not retrieve referencing guide: no subjects");
                    setResData({error: "Cannot retrieve data"});
                }

                const guideData = await fetch(`${gApi}?${gQString}`).then(data => data.json());
                const guides = guideData.filter(guide => {
                    return subjects.filter(subject => subject.slug != null)
                        .map(subject => subject.slug).includes(guide.slug);
                });
                const guidesRef = guides.map(guide => {
                    return ({
                        subject: guide.name, url: guide.friendly_url
                    });
                })
                setResData(guidesRef);
            } catch {
                console.error("Error retrieving referencing guide");
                setResData({error: "Cannot retrieve data"});
            }
        }

        getData();

    }, []);

    return (<Widget heading="Referencing and citing guide" resData={resData}>
        {intro && <Message>{intro}</Message>}
        <GenResults resData={resData}/>
    </Widget>)
}