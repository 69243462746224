const guideApi = process.env.REACT_APP_GUIDE_API;

const searchParams = {
    guide_types: "3",
    status: "1",
    expand: "tags"
};
const qString = new URLSearchParams(searchParams).toString();

export async function getSubs(query) {
    let subs = [];
    const subCode = new RegExp(/(\w{4})/);
    query = subCode.exec(query)[0].toUpperCase();
    const subApi = `${guideApi}?${qString}`;
    const guideData = await fetch(subApi).then(response => response.json());
    if ("error" in guideData) {
        throw "Cannot retrieve data";
    }
    const subGuides = guideData.filter(guide => {
        if (guide.tags) {
            return guide.tags.map(tag => tag["text"]).includes(query);
        }
    });
    subs = subGuides.map(guide => {
        return {name: guide.name, slug: guide.slug}
    });

    return subs;
}

export function verify(checkCode) {
    // Check that code is four letters followed by 3 numbers
    const codeCheck = new RegExp(/^[A-Za-z]{4}[0-9]{3}$/);
    return codeCheck.test(checkCode);
}